
@import 'variables';

.radio-switch {
    width: 55px;
    height: 25px;
    background-color: $grey-3;
    position: relative;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    cursor: pointer;
  
    .radio-switch_button {
      top: 3px;
      left: 5px;
      display: block;
      position: absolute;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      background-color: #fff;
      transition: all .45s ease-in-out;
    }
  
    .radio-switch_text {
      position: absolute;
      top: 3px;
      right: 5px;
      color: $grey-5;
      font-size: $body-tiny;
    }
  
    &.radio-switch-active {
      background-color: $ah-dark;
      .radio-switch_button {
        right: 5px;
        left: auto;
      }
      .radio-switch_text {
        right: auto;
        left: 5px;
        color: $whyte
      }
    }
    &.radio-switch-sm{
      width: 38px;
      height: 18px;
      .radio-switch_button{
        width: 12px;
        height: 12px;
      }
    }
    &:hover {
      background-color: $ah-primary;
    }
    &.radio-switch-disabled{
      cursor:default;
      background-color: $grey-3;
      opacity: .7;
      &:hover {
        background-color: $grey-3;
        opacity: .7;
      }
    }
   
  }