@import "~react-vis/dist/style";


body {
  margin: 0;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  height: 100%;
  min-height: 100vh;
  color: #F8F8FA;
  cursor: default;
  position: relative;
}
body::-webkit-scrollbar {
  display: none;
}
h1,
.h1 {
  font-size: 32px;
}

h2,
.h2 {
  font-size: 26px;
}

h3,
.h3 {
  font-size: 20px;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 16px;
  font-weight: 700;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .8px;
  margin-bottom: 4px;
}

p,
.p {
  font-size: 14px;
}

.body-tiny{
  font-size: 12px;
  font-weight: 600;
}

.body-tiny-grey{
  font-size: 12px;
  color: #c0c1c3;
  font-weight: 600;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* MODAL BACKDROP  */
.modal-backdrop {
  background-color: #000000;
}
.modal-backdrop.show {
  opacity: 0.6;
}

a, .link{
  color: #F8F8FA;
}

a:hover, .link:hover{
  color: #F8F8FA;
  opacity: .5;
}


.spinner-border{
  display: inline-block;
  width: 3rem;
  height: 3rem;
  vertical-align: -0.125em;
  border: 0.4em solid #0485F4;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}